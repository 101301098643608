import { Box, Button } from '@mentimeter/ragnar-ui';
import React, { useState, type ReactNode } from 'react';
import { Section } from 'src/ui/layout';
import { H2, Subheading } from 'src/ui/typography';
import { useMatch } from '@mentimeter/ragnar-device';
import { contentfulImageLoader } from 'src/cms/utils/utils';
import NextImage from 'src/components/image/NextImage';
import Tag from 'src/components/Tag';
interface DesktopImageBlockProps {
  tag: string;
  slides: {
    text: string;
    icon: ReactNode;
    desktop: { src: string; alt: string };
    mobile: { src: string; alt: string };
  }[];
  title: string;
  subtitle: string;
}

const DesktopImageBlock = ({
  tag,
  slides,
  title,
  subtitle,
}: DesktopImageBlockProps) => {
  const [activeButton, setActiveButton] = useState(0);
  const isBigDesktop = useMatch({ greaterThan: 3 });
  const isDesktop = useMatch({ greaterThan: 2 });
  return (
    <Section px={[4, 4, 4, 6]} py="space6" pb="space14">
      <Tag>{tag}</Tag>
      <H2
        fontSize={[5, 7, 8]}
        my="space6"
        fontWeight="regular"
        textAlign="center"
      >
        {title}
      </H2>
      <Subheading
        fontWeight="regular"
        fontSize={[2, '112.5']}
        maxWidth="680px"
        textAlign="center"
        mb="space8"
      >
        {subtitle}
      </Subheading>
      <Box
        flexDirection="row"
        gap={2}
        justifyContent="center"
        alignItems="center"
      >
        {slides.map((slide, index) => (
          <Button
            key={slide.text + index}
            onClick={() => setActiveButton(index)}
            variant={activeButton === index ? 'outline' : undefined}
            extend={() => ({ fontWeight: 'regular' })}
          >
            {slide.icon}
            {isDesktop && slide.text}
          </Button>
        ))}
      </Box>
      <Box
        mt={4}
        backgroundImage="url(https://static.mentimeter.com/static/images/DesktopBlockBackground.png)"
        alignItems="center"
        justifyContent="center"
        pt={[2, 3, 4]}
        borderRadius="4xl"
      >
        <NextImage
          loader={contentfulImageLoader}
          src={slides[activeButton]?.desktop.src ?? ''}
          alt={slides[activeButton]?.desktop.alt ?? ''}
          width={1038}
          height={502}
        />
        {isBigDesktop && (
          <Box position="absolute" bottom="-50px" right="-130px">
            <NextImage
              loader={contentfulImageLoader}
              src={slides[activeButton]?.mobile.src ?? ''}
              alt={slides[activeButton]?.mobile.alt ?? ''}
              width={320}
              height={600}
            />
          </Box>
        )}
      </Box>
    </Section>
  );
};

export default DesktopImageBlock;
